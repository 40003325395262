import React, { Component } from 'react';
import './App.css';
import HandheldList from './components/HandheldList';
import Tabletop from 'tabletop';
import Compare from  './components/Compare';

class App extends Component {
  constructor() {
    super();
    this.state = {
      showLoadingText: false,
      inputValue: '',
      handhelds: [],
      filterCategory: 'Brand',
    };
  }

  componentDidMount() {
    this.setState({showLoadingText:true});
    Tabletop.init({
        key: '1ysQ9_gdJKJu3t5JQvf8A1IMVPrzfljgiG41kSPfR6Io',
        callback: googleData => {
          //console.log('google sheet data -->', googleData);
          this.setState({
            showLoadingText: false,
            handhelds: googleData
          });
        },
        simpleSheet:true
    });
  }

  handheldFilterOnChange = (event, filterTitle) => {
    //console.log("onchange data -->", event)
    this.setState({
      inputValue: event,
      filterCategory: filterTitle, 
    })
  };

  render() {
    const loadingText = this.state.showLoadingText ? "loading..." : this.state.handhelds.brand;
    
    //create a new, sorted array to display and filter all handhelds in main section
    let listOfHandhelds = this.state.handhelds.sort(Compare('Handheld'))       
  
    const filteredHandhelds = 
      listOfHandhelds.filter(filterHandhelds => {
        return filterHandhelds[this.state.filterCategory].toLowerCase().includes(this.state.inputValue.toLowerCase())
      })

    return(
      <div className="App">      
        <div style={{height:"75px"}}/>
        <p>{loadingText}</p>

        <HandheldList filterHandhelds={filteredHandhelds}
              handheldFilterOnChange={this.handheldFilterOnChange}
              inputValue={this.state.inputValue}
              stateHandhelds={this.state.handhelds}
              stateFilterCategory={this.state.filterCategory} />
      </div>
    );    
  }
}

export default App; 
