import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import InfoIcon from '@material-ui/icons/Info';
import IconButton from '@material-ui/core/IconButton';
import SimpleAccordion from './AccoridanSpecs.js';

function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },

    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    },
}));

export default function SimpleModal(props) {
    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const body = (
        <div style={modalStyle} className={classes.paper}>
            <h2 id="simple-modal-title">{props.Brand} - {props.Handheld}</h2>
            {/* <ul id="simple-modal-description">
                    <li>Processor: {props.CPU}</li> 
                    <li>Processor Speed and Cores: {props[ 'CPU Clock Speed' ]} {props[ 'CPU Cores' ]}</li>          
                    <li>Processor Architecture: {props.Architecture}</li>
                    <li>SoC: {props.SoC}</li>
                    <li>Memory: {props.RAM}</li>
                    <li>GPU: {props.GPU}</li>
                    <li>Primary OS: {props.OS}</li>
                    <li>Screen Orientation: {props[ 'Form Factor' ]}</li>                     
                    <li>Screen Size: {props[ 'Screen Size' ]}</li> 
                    <li>Screen Type: {props[ 'Screen Type' ]}</li> 
                    <li>Screen Resolution: {props[ 'Screen Resolution' ]}</li> 
                    <li>Screen Aspect Ratio: {props[ 'Aspect Ratio' ]}</li> 
                    <li>Screen Material: {props[ 'Screen Lens' ]}</li> 
                    <li>Screen Brightness: {props[ 'Brightness Control' ]}</li>
                    <li>Battery Capacity: {props.Battery}</li>
                    <li>Battery Swappable: {props[ 'Swappable Battery' ]}</li> 
                    <li>Battery Indicator: {props[ 'Low Battery Indicator' ]}</li>
                    <li>Controls- D-Pad: {props[ 'D-Pad' ]}</li> 
                    <li>Controls- Analongs: {props.Analongs}</li>
                    <li>Controls- Face Buttons: {props[ 'Face Buttons' ]}</li>
                    <li>Controls- Shoulder Buttons: {props[ 'Shoulder Buttons' ]}</li>
                    <li>Controls- Other Buttons: {props[ 'Extra Buttons' ]}</li>
                    <li>Charging Port: {props[ 'Charge Port' ]}</li> 
                    <li>Storage Type: {props.Storage}</li> 
                    <li>Connectivity: {props.Connectivity}</li> 
                    <li>Video Output: {props[ 'Video Output' ]}</li> 
                    <li>Audio Output: {props[ 'Audio Output' ]}</li>
                    <li>Speaker: {props.Speaker}</li>
                    <li>Volume Control: {props[ 'Volume Control' ]}</li>
                    <li>Rumble Feature: {props[ 'Rumble' ]}</li>
                    <li>Power Control: {props[ 'Power Control' ]}</li>
                    <li>Console Dimensions: {props[ 'Dimensions' ]}</li>
                    <li>Console Weight: {props[ 'Weight' ]}</li>
                    <li>Console Colors: {props[ 'Colors' ]}</li>
                    <li>Latest Emulated Systems: {props[ 'Emulation Limit' ]}</li>
                    <li>Console Site: {props[ 'Vendor Link' ]}</li>
                
            </ul> */}
            <SimpleAccordion {...props}/>
            <SimpleModal />
        </div>
    );

    return (
        <div>         
            <IconButton aria-label={`info about`} className={classes.icon} onClick={handleOpen}>
                <InfoIcon />
            </IconButton>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>
        </div>
    );
}
