import odroid_go_advance from './/Odroid Go Advance - Black Edition.svg';
import piBoyDmg from './PiBoy DMG.svg';
import q900 from './Q900.svg';
import q400 from './Q400.svg';
import oneUP_Pi_Boy_Micro from './1UP_Pi-Boy_Micro.svg';
import oneUP_Pi_Boy_XL from './1UP_Pi-Boy_XL.svg';
import oneUP_PiX_Portable from './1UP_PiX_Portable.svg';
import seventeenPocket_System from './17Pocket_System.svg';
import thirtyTwoBlit from './32blit.svg';
import Analogue_Pocket from './Analogue_Pocket.svg';
import Anniversary_Edition_IPS_Screen_Model from './Anniversary_Edition_IPS_Screen_Model.svg';
import Bittboy_V3 from './Bittboy_V3.svg';
import Cartboy from './Cartboy.svg';
import Concept_UFO from './Concept_UFO.svg';
import Defender_MultiMix_Magic_MiShark64 from './Defender_MultiMix_Magic-MiShark64.svg';
import Digi_Dock from './Digi_Dock.svg';
import Digi_RetroBoy from './Digi_RetroBoy.svg';
import Dingoo_A320 from './Dingoo_A320.svg';
import Dingoo_A380 from './Dingoo_A380.svg';
import DragonBox_Pyra from './DragonBox_Pyra.svg';
import Freeplay_CM3_Zero from './Freeplay_CM3_Zero.svg';
import Game_Hat from './Game_Hat.svg';
import GameBoy_Advance_Zero from './GameBoy_Advance_Zero.svg';
import GameBoy_Zero from './GameBoy_Zero.svg';
import GameGadget from './GameGadget.svg';
import GamePi20 from './GamePi20.svg';
import GamePi43 from './GamePi43.svg';
import Gameshell from './Gameshell.svg';
import GCW_Zerofrom from './GCW_Zero.svg';
import Gemei_A330 from './Gemei_A330.svg';
import Gemei_X760plus from './Gemei_X760+.svg';
import Gizmondo from './Gizmondo.svg';
import GKD_350H from './GKD-350H.svg';
import Gopher_2 from './Gopher_2.svg';
import GP2X_Caanoo from './GP2X_Caanoo.svg';
import GP2X_F_200 from './GP2X_F-200.svg';
import GP2X_Wiz from './GP2X_Wiz.svg';
import GP2X from './GP2X.svg';
import GP32 from './GP32.svg';
import GPD_G5A from './GPD_G5A.svg';
import GPD_Win_2_2019_Model from './GPD_Win_2_2019_Model_.svg';
import GPD_Win_Max from './GPD_Win_Max.svg';
import GPD_Win from './GPD_Win.svg';
import GPD_X7 from './GPD_X7.svg';
import GPD_XD from './GPD_XD.svg';
import GPD_XDplus from './GPD_XD+.svg';
import GPi_Case from './GPi_Case.svg';
import K101_Plus from './K101_Plus.svg';
import LCL_Pi_Gameboy from './LCL_Pi_Gameboy.svg';
import LDK_Game from './LDK_Game.svg';
import LDK_Landscape from './LDK_Landscape.svg';
import Lyra from './Lyra.svg';
import MGSP from './MGSP.svg';
import MOQI_i7S from './MOQI_i7S.svg';
import Much_W1_78P01 from './Much_W1_78P01.svg';
import New_PoscketGo_PlayGo_Miyoo_Max from './New_PoscketGo_PlayGo_Miyoo_Max.svg';
import Odroid_Go_Advance from './Odroid_Go_Advance.svg';
import Odroid_Go from './Odroid_Go.svg';
import One_GX from './One_GX.svg';
import Pandora from './Pandora.svg';
import PAP_KIII_Plus from './PAP_KIII_Plus.svg';
import PMP_V from './PMP_V.svg';
import PocketGo from './PocketGo.svg';
import PowKiddy_Q90 from './PowKiddy_Q90.svg';
import PowKiddy_X2 from './PowKiddy_X2.svg';
import PowKiddy_X15 from './PowKiddy_X15.svg';
import PowKiddy_X18 from './PowKiddy_X18.svg';
import PowKiddy_X19 from './PowKiddy_X19.svg';
import Retro_Carnival_CM3 from './Retro_Carnival_CM3.svg';
import Retro_CM3 from './Retro_CM3.svg';
import Retro_V3s from './Retro_V3s.svg';
import RetroGame_RS_97_Plus from './RetroGame_RS-97_Plus.svg';
import RetroGame_RS_97 from './RetroGame_RS-97.svg';
import Retroid_Pocket from './Retroid_Pocket.svg';
import RetroMini_RS_90 from './RetroMini_RS-90.svg';
import Retrostone_1 from './Retrostone_1.svg';
import Retrostone_2 from './Retrostone_2.svg';
import RG_300 from './RG-300.svg';
import RG_350 from './RG-350.svg';
import RG_350M from './RG-350M.svg';
import Ritmix_RZX_50 from './Ritmix_RZX-50.svg';
import Sega_Gopher from './Sega_Gopher.svg';
import Shield_Portable from './Shield_Portable.svg';
import Smach_Z from './Smach_Z.svg';
import Tapwave_Zodiac from './Tapwave_Zodiac.svg';
import Tiny_GamePi15 from './Tiny_GamePi15.svg';
import Z_Pocket_Game from './Z-Pocket_Game.svg';
import default_console_image from './game-console.svg';

const handheldImages = [
    {image: default_console_image, title: 'default'},
    {image: odroid_go_advance, title: 'Odroid Go Advance - Black Edition'},
    {image: piBoyDmg, title: 'PiBoy DMG'},
    {image: q400, title: 'Q400'},
    {image: q900, title: 'Q900'},
    {image: oneUP_Pi_Boy_Micro, title: '1UP Pi-Boy Micro'},
    {image: oneUP_Pi_Boy_XL, title: '1UP Pi-Boy XL'},
    {image: oneUP_PiX_Portable, title: '1UP PiX Portable'},
    {image: seventeenPocket_System, title: '17Pocket System'},
    {image: thirtyTwoBlit, title: '32blit'},
    {image: Analogue_Pocket, title: 'Analogue Pocket'},
    {image: Anniversary_Edition_IPS_Screen_Model, title: 'RetroGame RS-97 (Anniversary Edition / IPS Screen Model)'},
    {image: Bittboy_V3, title: 'Bittboy V3'},
    {image: Cartboy, title: 'Cartboy'},
    {image: Concept_UFO, title: 'Concept UFO'},
    {image: Defender_MultiMix_Magic_MiShark64, title: 'Letcool N350JP / Defender MultiMix Magic / MiShark64'},
    {image: Digi_Dock, title: 'Digi Dock'},
    {image: Digi_RetroBoy, title: 'Digi RetroBoy'},
    {image: Dingoo_A320, title: 'Dingoo A320'},
    {image: Dingoo_A380, title: 'Dingoo A380'},
    {image: DragonBox_Pyra, title: 'DragonBox Pyra'},
    {image: Freeplay_CM3_Zero, title: 'Freeplay CM3 / Zero'},
    {image: Game_Hat, title: 'Game Hat'},
    {image: GameBoy_Advance_Zero, title: 'GameBoy Advance Zero'},
    {image: GameBoy_Zero, title: 'GameBoy Zero'},
    {image: GameGadget, title: 'GameGadget'},
    {image: GamePi20, title: 'GamePi20'},
    {image: GamePi43, title: 'GamePi43'},
    {image: Gameshell, title: 'Gameshell'},
    {image: GCW_Zerofrom, title: 'GCW Zero'},    
    {image: Gemei_A330, title: 'Gemei A330'},
    {image: Gemei_X760plus, title: 'Gemei X760+'},
    {image: Gizmondo, title: 'Gizmondo'},
    {image: GKD_350H, title: 'GKD-350H'},
    {image: Gopher_2, title: 'Gopher 2'},
    {image: GP2X_Caanoo, title: 'GP2X Caanoo'},
    {image: GP2X_F_200, title: 'GP2X F-200'},
    {image: GP2X_Wiz, title: 'GP2X Wiz'},
    {image: GP2X, title: 'GP2X'},
    {image: GP32, title: 'GP32'},
    {image: GPD_G5A, title: 'GPD G5A'},
    {image: GPD_Win_2_2019_Model, title: 'GPD Win 2 (2019 Model)'},
    {image: GPD_Win_Max, title: 'GPD Win Max'},
    {image: GPD_Win, title: 'GPD Win'},
    {image: GPD_X7, title: 'GPD X7'},
    {image: GPD_XD, title: 'GPD XD'},
    {image: GPD_XDplus, title: 'GPD XD+'},
    {image: GPi_Case, title: 'GPi Case'},
    {image: K101_Plus, title: 'K101 Plus'},
    {image: LCL_Pi_Gameboy, title: 'LCL Pi Gameboy'},
    {image: LDK_Game, title: 'LDK Game'},
    {image: LDK_Landscape, title: 'LDK Landscape'},
    {image: MGSP, title: 'MGSP'},
    {image: MOQI_i7S, title: 'MOQI i7S'},
    {image: Much_W1_78P01, title: 'Much W1 / 78P01'},
    {image: New_PoscketGo_PlayGo_Miyoo_Max, title: 'New PocketGo / PlayGo / Miyoo Max'},
    {image: Odroid_Go_Advance, title: 'Odroid Go Advance'},
    {image: Odroid_Go, title: 'Odroid Go'},
    {image: One_GX, title: 'One GX'},
    {image: Pandora, title: 'Pandora'},
    {image: PAP_KIII_Plus, title: 'PAP KIII Plus'},
    {image: PMP_V, title: 'PMP V'},
    {image: PocketGo, title: 'PocketGo'},
    {image: PowKiddy_Q90, title: 'PowKiddy Q90'},
    {image: PowKiddy_X2, title: 'Lyra'},
    {image: PowKiddy_X2, title: 'PowKiddy X2'},
    {image: PowKiddy_X15, title: 'PowKiddy X15'},
    {image: PowKiddy_X18, title: 'PowKiddy X18'},
    {image: PowKiddy_X19, title: 'PowKiddy X19'},
    {image: Retro_Carnival_CM3, title: 'Retro Carnival CM3'},
    {image: Retro_CM3, title: 'Retro CM3'},
    {image: Retro_V3s, title: 'Retro V3s'},
    {image: RetroGame_RS_97_Plus, title: 'RetroGame RS-97 Plus'},
    {image: RetroGame_RS_97, title: 'RetroGame RS-97'},
    {image: Retroid_Pocket, title: 'Retroid Pocket'},
    {image: RetroMini_RS_90, title: 'RetroMini RS-90'},
    {image: Retrostone_1, title: 'Retrostone 1'},
    {image: Retrostone_2, title: 'Retrostone 2'},
    {image: RG_300, title: 'RG-300'},
    {image: RG_350, title: 'RG-350'},
    {image: RG_350M, title: 'RG-350M'},
    {image: Ritmix_RZX_50, title: 'Ritmix RZX-50'},
    {image: Sega_Gopher, title: 'Sega Gopher'},
    {image: Shield_Portable, title: 'Shield Portable'},
    {image: Smach_Z, title: 'Smach Z'},
    {image: Lyra, title: 'Lyra'},
    {image: Tapwave_Zodiac, title: 'Tapwave Zodiac'},
    {image: Tiny_GamePi15, title: 'Tiny GamePi15'},
    {image: Z_Pocket_Game, title: 'Z-Pocket Game'},
];

export default handheldImages;