import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Compare from  './Compare';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
        maxWidth: 200,
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

let filterArray = [];

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

function createFilterArray (arrayData, filterName) {
    const seen = new Set();
    const filteredArr = arrayData.filter(el => {
        const duplicate = seen.has(el[filterName]);
        seen.add(el[filterName]);
        return !duplicate;
    });
    return filteredArr.sort(Compare(filterName));;
}

export default function MultipleSelect(props) {
    let filterName = props.filterName;
    let stateHandhelds = props.stateHandhelds;
    const classes = useStyles();
    const theme = useTheme();
    const [personName, setPersonName] = React.useState([]);
    const handleChange = (event) => {
        setPersonName(event.target.value);
        props.handheldFilterOnChange(event.target.value.join(' '), filterName);
    };

    filterArray=createFilterArray(stateHandhelds, filterName);
    
    return (
        <div>
            <FormControl className={classes.formControl}>
                <InputLabel id="demo-mutiple-name-label">{props.filterName}</InputLabel>
                <Select
                    labelId="demo-mutiple-name-label"
                    id="demo-mutiple-name"
                    multiple
                    value={personName}
                    onChange={handleChange}
                    input={<Input />}
                    MenuProps={MenuProps}
                >
                    {filterArray.map((name) => (
                        <MenuItem key={name[filterName]} value={name[filterName]} style={getStyles(name[filterName], personName, theme)}>
                            {name[filterName]}
                        </MenuItem>
                    ))}
                    
                </Select>
            </FormControl>
        </div>
    );
}
