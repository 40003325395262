import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    }));

    export default function SimpleAccordion(props) {
        console.log(props)
    const classes = useStyles();
    return (
        <div className={classes.root}>
        <Accordion>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            >
            <Typography className={classes.heading}>General Tech Specs</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
                    <li><b>Model:</b> {props[ 'CPU' ]}</li>
                    <li><b>Speed:</b> {props[ 'CPU Clock Speed' ]}</li>
                    <li><b>Cores:</b> {props[ 'CPU Cores' ]}</li>           
                    <li><b>Architecture:</b> {props[ 'Architecture' ]}</li>
                    <li><b>SoC:</b> {props[ 'SoC' ]}</li>
                    <li><b>Memory:</b> {props[ 'RAM' ]}</li>
                    <li><b>GPU:</b> {props[ 'GPU' ]}</li>
                    <li><b>Primary OS:</b> {props[ 'OS' ]}</li>
            </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            >
            <Typography className={classes.heading}>Screen</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
                <li><b>Orientation:</b> {props[ 'Form Factor' ]}</li>                     
                <li><b>Size:</b> {props[ 'Screen Size' ]}</li> 
                <li><b>Type:</b> {props[ 'Screen Type' ]}</li> 
                <li><b>Resolution:</b> {props[ 'Screen Resolution' ]}</li> 
                <li><b>Aspect Ratio:</b> {props[ 'Aspect Ratio' ]}</li> 
                <li><b>Material:</b> {props[ 'Screen Lens' ]}</li> 
                <li><b>Brightness:</b> {props[ 'Brightness Control' ]}</li>
            </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            >
            <Typography className={classes.heading}>Battery</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
                <li><b>Capacity:</b> {props.Battery}</li>
                <li><b>Swappable:</b> {props[ 'Swappable Battery' ]}</li> 
                <li><b>Indicator:</b> {props[ 'Low Battery Indicator' ]}</li>
            </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            >
            <Typography className={classes.heading}>Connectivity</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
                <li><b>Charging Port:</b> {props[ 'Charge Port' ]}</li> 
                <li><b>Connectivity:</b> {props[ 'Connectivity ']}</li> 
                <li><b>Video Output:</b> {props[ 'Video Output' ]}</li> 
                <li><b>Audio Output:</b> {props[ 'Audio Output' ]}</li>
                <li><b>Speaker:</b> {props[ 'Speaker' ]}</li>
            </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            >
            <Typography className={classes.heading}>Controls</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
                <li><b>D-Pad:</b> {props[ 'D-Pad' ]}</li> 
                <li><b>Analongs:</b> {props[ 'Analongs' ]}</li>
                <li><b>Face Buttons:</b> {props[ 'Face Buttons' ]}</li>
                <li><b>Shoulder Buttons:</b> {props[ 'Shoulder Buttons' ]}</li>
                <li><b>ther Buttons:</b> {props[ 'Extra Buttons' ]}</li>
                <li><b>Volume:</b> {props[ 'Volume Control' ]}</li>
                <li><b>Power:</b> {props[ 'Power Control' ]}</li>
            </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            >
            <Typography className={classes.heading}>Dimensions</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
                <li><b>Dimensions:</b> {props[ 'Dimensions' ]}</li>
                <li><b>Weight:</b> {props[ 'Weight' ]}</li>
                <li><b>Colors:</b> {props[ 'Colors' ]}</li>
            </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            >
            <Typography className={classes.heading}>Features</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
                <li><b>Rumble:</b> {props[ 'Rumble' ]}</li>
                <li><b>Newest Emulated Systems:</b> {props[ 'Emulation Limit' ]}</li>
            </Typography>
            </AccordionDetails>
        </Accordion>
        </div>
    );
}
