import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import handheldImages from '../resources/handheld_images/HandheldImagesIndex';
import SimpleModal from './ModalInfo';

const useStyles = makeStyles((theme) => ({
  imgHandheldView: {
    width: '100%',
    height: '100%',
  },
  card:{
    height: 250,
    margin: '10px',
    width: 272,
  },
  subtitle:{
    background: 'rgba(3, 75, 48, 0.75)'
  },
}));

function getHandheldImage(handheld){
  for(var i = 0; i<handheldImages.length; i++) {
    if (handheldImages[i].title.localeCompare(handheld) === 0) { 
      return handheldImages[i].image;
    }
  }
  return handheldImages[0].image;
}

const HandheldCard = (props) => {
  const classes = useStyles();
  const {Handheld, Brand} = props.handhelds;

  return (
      
      <GridListTile key={Handheld} className={classes.card}>
        <img src={getHandheldImage(Handheld)} name="handheld_image"
          className={classes.imgHandheldView}
          alt={Handheld} />
        <GridListTileBar
          className={classes.subtitle}
          title={Handheld}
          subtitle={<span>{Brand}</span>}
          actionIcon={<SimpleModal {...props.handhelds}/>} 
          />
      </GridListTile>      
  );
}

export default HandheldCard;
