import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MultipleSelect from './MultipleSelect'
import PatreonLogo from '../resources/patreon_brand_assets/Patreon Wordmark/PNG/Digital-Patreon-Wordmark_White.png'
import PaypalButton from './PaypalButton';
import Link from '@material-ui/core/Link';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      marginBottom: '15px',
      background: '#004225',
    },
    [theme.breakpoints.down('sm')]: {
      background: '#004225',
    }
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function ResponsiveDrawer(props) {
  const { window } = props;

  // if (props.filteredHandheldsData != null){console.log("YES!")}

/*   const listHandhelds = props.filteredHandheldsData.map((handheldsData) =>
    console.log("ResponsiveDrawer.js-->filteredHandheldsData--> " + {handheldsData}) 
  ) */

  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <List>
        {['Brand', 'CPU', 'GPU', 'RAM', 'SoC'].map((text, index) => (
          <MultipleSelect filterName={text} 
            stateHandhelds={props.stateHandhelds}
            handheldFilterOnChange={props.handheldFilterOnChange} />
        ))}
      </List>
      <Divider />
      <div>
        <Typography variant="h6" noWrap>
          Credits
        </Typography>
        <Typography variant="body1" noWrap>
            <List>
              Database Creator: timeisart
            </List>
            <List>
              <Link target="_blank" href="https://docs.google.com/spreadsheets/d/1irg60f9qsZOkhp0cwOU7Cy4rJQeyusEUzTNQzhoTYTU/edit#gid=0" rel="noreferrer">
                The Database
              </Link>
            </List>
            <Divider variant="middle"/>
            <List>              
                Website Creator: <Link target="_blank" href="https://meridity.com" rel="noreferrer">Meridity</Link>              
            </List>
            <List>
              <Link target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSfOqDHDKreDMmK-Zg3nMN3H9BW3Z9nCcW57aPJPNWw8amaxlA/viewform?usp=sf_link" rel="noreferrer">
                Bugs & Features
              </Link>
            </List>
          </Typography>
      </div>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            Handheld Gaming Systems
          </Typography>
          <div className="right">
            <PaypalButton/>

            {/* <a href="https://patreon.com" target="_blank" rel="noopener noreferrer">
            <img 
              src={PatreonLogo} 
              alt="Patreon Logo"              
              width='10%'
              height ='10%' 
              margin-left='550px'/>
            </a> */}            
          </div>          
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
