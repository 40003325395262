import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import HandheldCard from './HandheldCard';
//import { ListSubheader } from '@material-ui/core';
import ResponsiveDrawer from './ResponsiveDrawer';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    "width": '70%',
    "height": 'auto',
    "overflowY": 'auto',
    "padding": '20',
    "justify-content": 'center'
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },  
}));

export default function TitlebarGridList(props) {
  const classes = useStyles();
  const columns = 4;

  return (
    <div className={classes.root}>
      <ResponsiveDrawer stateHandhelds={props.stateHandhelds}
                        handheldFilterOnChange={props.handheldFilterOnChange}
      />
      <GridList cellHeight={250} cols={columns} className={classes.gridList}>
{/*         <GridListTile key="Subheader" cols={columns} style={{ height: 'auto' }}>
          <ListSubheader component="div">
            <label htmlFor="search">Search by SYSTEM NAME </label>
            <input type="text" value = {props.inputValue} onChange={props.handheldFilterOnChange}/>
          </ListSubheader>          
        ))}*/}
        
        {props.filterHandhelds.map((tile) => ( 
          <HandheldCard key={tile.Handheld} handhelds={tile}/>
        ))}
      </GridList>
    </div>
  );
}
