export default function Compare(property) {
    return function(a, b) {
        const itemA = a[property].toLowerCase();
        const itemB = b[property].toLowerCase();

        let comparison = 0;
        if (itemA > itemB) {
            comparison = 1;
        } else if (itemA < itemB) {
            comparison = -1;
        }
        return comparison;
    }
}